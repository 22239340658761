import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import resumeStyle from "./resume.css";
import { workExperience, education, skills } from "../../resume-info";
import InfoSnippet from "../info-snippet/info-snippet";

function Resume(){

    const appContext = useContext(AppContext);
    const pageTitleSuffix =  appContext.pageTitleSuffix;

    useEffect(() => {
        appContext.setPageTitle("Resume " + pageTitleSuffix);
    }, []);

    return(
        <section className="resume">
            <div className="row title">
                <div className="left"><h1 className="title">Resume</h1></div>
                <div className="right"></div>
            </div>            
            {/* Work Experience */}
            <div className="row">
                <div className="left"><h1>Work Experience</h1></div>
                <div className="right">{workExperience.map(InfoSnippet)}</div>            
            </div>
            <div className="custom-line" />
            {/* Education */}
            <div className="row">
                <div className="left"><h1>Education</h1></div>
                <div className="right">{education.map(InfoSnippet)}</div>
            </div>
            <div className="custom-line" />
            {/* Skills */}
            <div className="row">
                <div className="left"><h1>Skills</h1></div>
                <div className="right">{skills.map(InfoSnippet)}</div>
            </div>            
        </section>
    );
}

export default Resume;