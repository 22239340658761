import React from "react";

function InfoSnippet(info){
    
    return(
        <div key={info.id} className="segment">
            <p className="work-title">{info.jobTitle}</p>
            <p className="location">{info.jobCoLocationPeriod}</p>
            { SetBulletPoints(info.bulletPoints) }
        </div>
    );
}

function SetBulletPoints(bulletPoints){
    return bulletPoints[1] ? <ul>{ bulletPoints.map(SetBulletPoint) }</ul> : null;
}

// bulletPoint[1] - main bullet points
// bulletPoint[2] - sub bullet points
function SetBulletPoint(bulletPoint){
    return([ 
        ShowBpTitle(bulletPoint[0]), // if bullets have a title
        // bullets
        <li key={bulletPoint}>
            <p>{ bulletPoint[1] }</p>
            { bulletPoint[2] ? (SetSubBulletPoints(bulletPoint[2])) : null}
        </li>
    ]);
}

function ShowBpTitle(title){
    // Title bullet section
    return title ? <p key={title.length} className="bullet-title">{title}</p> : null; // using length as key for quick fix
}

function SetSubBulletPoints(info){
    return <ul className="sub-bullets">{info ? info.map(SubBulletPoint) : null}</ul>
}

function SubBulletPoint(subBulletPoint){
    return( 
        // sub-bullets
        <li key={subBulletPoint}>
            <p>{subBulletPoint}</p>
        </li>
    );
}

export default InfoSnippet;