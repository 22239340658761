import React from "react";
import headerStyle from "./footer.css";

function Footer(){
    var date = new Date().getFullYear();

    return(
        <footer>
            <div className="phone">
                <p>Phone</p>
                <p>+1 956-740-1271</p>
            </div>
            <div className="email">
                <p>Email</p>
                <p>eduardojuarezdev@gmail.com</p>
            </div>
            <div className="copyright">
                <p>© {date} by Eduardo Juarez</p>
            </div>
        </footer>
    );
}

export default Footer;