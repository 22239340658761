import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import Navbar from "../navbar/navbar";
import NavItem from "../navbar/navitem";
import menuImg from "../../images/menu.png";
import headerStyle from "./header.css";

function Header(){

    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    
    function showDropdown() {
        document.body.style.overflow = "hidden";
        return 'flex';
    }

    function hideDropdown(){
        document.body.style.overflow = "visible";
        return 'none';
    }

    function checkCheckbox(){
        document.getElementById("check").checked = true;
        setIsActive(!isActive);
    }

    return(
        <header>
            <div className="name" onClick={() => navigate("/")}>
                <svg height="100" width="45">
                    <circle cx="12" cy="65" r="12" strokeWidth="3" fill="#242F9B" />
                </svg>                
                <h2>Eduardo Juarez</h2>
                <p>Software Engineer</p>
            </div>
            <div className="name-mob">
                <div>
                    <svg height="100" width="45">
                        <circle cx="12" cy="37" r="12" strokeWidth="3" fill="#242F9B" />
                    </svg>
                    <h2>Eduardo Juarez</h2>
                </div>
                <p>Software Engineer</p>
            </div>
            <nav className="nav-full">
                <NavLink to="/"><p className="link-seperator">Home</p></NavLink>
                <NavLink to="/resume"><p className="link-seperator">Resume</p></NavLink>
                <NavLink to="/portfolio" ><p>Portfolio</p></NavLink>
            </nav>
            <nav className="nav-mobile">
                <label>
                    <input type="checkbox" id="check" readOnly checked={ isActive } onClick={()=>setIsActive(!isActive)}/> 
                    <span></span>
                    <span></span>
                    <span></span>
                </label>   
                <div className="dropdown" style={ isActive ? {display : showDropdown(), touchAction:"none"} : { display: hideDropdown()} }>
                    <NavLink to="/" onClick={ checkCheckbox }><p>Home</p></NavLink>
                    <NavLink to="/resume" onClick={ checkCheckbox }><p>Resume</p></NavLink>
                    <NavLink to="/portfolio" onClick={ checkCheckbox }><p>Portfolio</p></NavLink>
                </div>
            </nav>         
        </header>
    );
}

export default Header;