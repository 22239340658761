import React, { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { Swiper } from 'swiper/react';
import containerImg1 from "../../images/containers1.png";
import containerImg2 from "../../images/containers2.png";
import containerImg3 from "../../images/containers3.png";
import containerImg4 from "../../images/containers4.png";
import containerImg5 from "../../images/containers5.png";
import containerImg6 from "../../images/containers6.png";
import containerImg7 from "../../images/containers7.png";
import containerImg8 from "../../images/containers8.png";
import containerImg9 from "../../images/containers9.png";
import SwiperView from "../swiper-view";
import containersStyle from "./containers.css";

function Containers(){

    const appContext = useContext(AppContext);
    const pageTitleSuffix =  appContext.pageTitleSuffix;

    useEffect(() => {
        appContext.setPageTitle("IMC Driver Log " + pageTitleSuffix);
    }, []);

    return(
        <section className="containers">
            <p className="title">IMC Driver Log App</p>
            <div className="boxes">
                <div className="box keep-track-images">
                    <img src={ containerImg1 } />
                    <img src={ containerImg2 } />
                </div>
                <div className="box keep-track-carousel">
                    <SwiperView class="kt-container" images={[containerImg1, containerImg2]} />
                </div>
                <div className="box keep-track">
                    <h3>Keep track of your trips</h3>
                    <p>With this app, IMC Recycling is able to keep track of the trips made to customers and where they took place. This is a cross platform app developed with Xamarin Forms for iOS and Android.</p>
                </div>
                <div className="box keep-track-mobile">
                    <h3>Keep track of your trips</h3>
                    <SwiperView class="kt-container" images={[containerImg1, containerImg2]} />   
                    <p>With this app, IMC Recycling is able to keep track of the trips made to customers and where they took place. This is a cross platform app developed with Xamarin Forms for iOS and Android.</p>
                </div>                
                <div className="box manage-trips">
                    <h3>Manage Trips</h3>
                    <p>Driver can add, edit or view Trips</p>
                    <div className="manage-trips-images">
                        <img src={ containerImg3 } />
                        <img src={ containerImg4 } />   
                        <img src={ containerImg5 } />
                    </div>
                    <SwiperView class="manage-trips-carousel" images={[containerImg3, containerImg4, containerImg5]} />                                       
                </div>
                <div className="box add-view-customers">
                    <div>
                        <h3>Add or View Customers</h3>
                        <p>Driver can search for Customers using auto filtering or just by scrolling. New Customers can also be added.</p>
                    </div>
                </div>  
                <div className="box advc-imgs">
                    <img className="advc-img" src={ containerImg6 } />
                    <img className="advc-img" src={ containerImg7 } />
                    <SwiperView class="advc-carousel" images={[containerImg6, containerImg7]} />
                </div>
                <div className="box advc-mobile">
                    <h3>Add or View Customers</h3>
                    <SwiperView class="advc-carousel" images={[containerImg6, containerImg7]} />                      
                    <p>Driver can search for Customers using auto filtering or just by scrolling. New Customers can also be added.</p>
                </div>      
                <div className="box container-history">
                    <h3>View History</h3>
                    <p>User can view past months of historical data</p>
                    <div className="h-imgs">
                        <img src={ containerImg8 } />
                        <img src={ containerImg9 } />                        
                    </div>
                    <SwiperView class="h-carousel" images={[containerImg8, containerImg9]} />                    
                </div>    
            </div>      
        </section>

    );
}

export default Containers;