import React from "react";
import { Link } from "react-router-dom";
import headerStyle from "./circular-button.css";

// style can be circle1 or circle2

function CircularButton(props){

    return(  
        <div className={"circular-button " + props.type}>
            <Link to={props.link}><div>{props.title}</div></Link>
        </div>
    );
}

export default CircularButton;